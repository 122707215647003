import React, { useEffect, useState } from "react";
import MediaTabs from "./MediaTabs";
import Latest from "./Latest";
import Albums from "./Albums";
import Gallery from "./Gallery";
import Other from "./Other";
import "./styles/MediaMain.css";
import Details from "./album/Details";
import Tracks from "./album/Tracks";
import Videos from "./album/Videos";
import Images from "./album/Images";

export default function MediaMain({
  playingType,
  setPlayingType,
  albumID,
  setAlbumID,

  /* Latest tracks */
  path,
  toMinutes,
  openPlayer,
  setList,
  playing,
  currentTime,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  tracks,
  albums,

  /* Latest videos */
  videos,
  mouseDown,

  /* Gallery */
  images,
}) {
  const [currentMedia, setCurrentMedia] = useState("latest");

  useEffect(() => {
    if (albumID !== null) {
      setCurrentMedia("album");
    }
  }, [albumID]);

  useEffect(() => {
    if (currentMedia !== "album") {
      setAlbumID(null);
    }
  }, [setAlbumID, currentMedia]);

  return (
    <MediaTabs
      path={path}
      currentMedia={currentMedia}
      setCurrentMedia={setCurrentMedia}
    >
      {currentMedia === "latest" ? (
        <Latest
          playingType={playingType}
          setPlayingType={setPlayingType}
          setAlbumView={setAlbumID}
          /* Tracks */
          toMinutes={toMinutes}
          openPlayer={openPlayer}
          setList={setList}
          playing={playing}
          currentTime={currentTime}
          setPlaying={setPlaying}
          currentPlaying={currentPlaying}
          setCurrentPlaying={setCurrentPlaying}
          tracks={tracks}
          albums={albums}
          /* Videos */
          videos={videos}
          mouseDown={mouseDown}
          /* Gallery */
          images={images}
        />
      ) : currentMedia === "albums" ? (
        <Albums
          albums={albums}
          viewAlbum={setAlbumID}
          toMinutes={toMinutes}
          tracks={tracks}
        />
      ) : currentMedia === "gallery" ? (
        <Gallery />
      ) : currentMedia === "other" ? (
        <Other />
      ) : currentMedia === "album" && albumID !== null ? (
        <div key={albumID}>
          <Details
            albums={albums}
            allTracks={tracks}
            allVideos={videos}
            id={albumID}
            viewAlbum={setAlbumID}
            toMinutes={toMinutes}
          />
          <Tracks
            id={albumID}
            albums={albums}
            tracks={tracks}
            toMinutes={toMinutes}
            currentTime={currentTime}
            openPlayer={openPlayer}
            playing={playing}
            setPlaying={setPlaying}
            currentPlaying={currentPlaying}
            setCurrentPlaying={setCurrentPlaying}
            setList={setList}
            viewAlbum={setAlbumID}
          />
          <Videos
            videos={
              videos[0] !== "VIDEOS_NOT_YET_LOADED"
                ? videos.filter((video) => video.album === albumID)
                : videos
            }
            albums={albums}
            playingType={playingType}
            setPlayingType={setPlayingType}
            toMinutes={toMinutes}
            mouseDown={mouseDown}
            viewAlbum={setAlbumID}
          />
          <Images
            images={
              images[0] !== "IMAGES_NOT_YET_LOADED"
                ? images.filter((image) => image.album === albumID)
                : images
            }
            albums={albums}
            viewAlbum={setAlbumID}
          />
        </div>
      ) : (
        <></>
      )}
    </MediaTabs>
  );
}
