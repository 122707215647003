import React from "react";
import MediaHeading from "./MediaHeading";
import LatestAlbums from "./LatestAlbums.jsx";
import LatestAudio from "./LatestAudio.jsx";
import LatestVideos from "./LatestVideos.jsx";
import LatestImages from "./LatestImages.jsx";
import "./styles/Latest.css";

export default function Latest({
  playingType,
  setPlayingType,
  setAlbumView,

  /* Tracks */
  toMinutes,
  openPlayer,
  setList,
  playing,
  currentTime,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  tracks,
  albums,

  /* Videos */
  videos,
  mouseDown,

  /* Gallery */
  images,
}) {
  return (
    <div className="media-latest">
      <section className="latest-albums">
        <MediaHeading link={{ url: "/media?=albums", text: "VIEW ALL" }}>
          LATEST ALBUMS
        </MediaHeading>
        <LatestAlbums
          albums={albums}
          setAlbumView={setAlbumView}
          toMinutes={toMinutes}
          tracks={tracks}
        />
      </section>
      <section className="latest-audio">
        <MediaHeading link={{ url: "#", text: "VIEW ALL" }}>
          LATEST AUDIO
        </MediaHeading>
        <LatestAudio
          toMinutes={toMinutes}
          openPlayer={openPlayer}
          setList={setList}
          playing={playing}
          currentTime={currentTime}
          setPlaying={setPlaying}
          currentPlaying={currentPlaying}
          setCurrentPlaying={setCurrentPlaying}
          tracks={tracks}
          albums={albums}
          viewAlbum={setAlbumView}
        />
      </section>
      <section className="latest-videos">
        <MediaHeading link={{ url: "#", text: "VIEW ALL" }}>
          LATEST VIDEOS
        </MediaHeading>
        <LatestVideos
          playingType={playingType}
          setPlayingType={setPlayingType}
          toMinutes={toMinutes}
          videos={videos}
          albums={albums}
          mouseDown={mouseDown}
          viewAlbum={setAlbumView}
        />
      </section>
      <section className="latest-images">
        <MediaHeading link={{ url: "#", text: "VIEW ALL" }}>
          LATEST IMAGES
        </MediaHeading>
        <LatestImages
          images={images}
          albums={albums}
          viewAlbum={setAlbumView}
        />
      </section>
    </div>
  );
}
