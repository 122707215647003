import React, { useState } from "react";
import LiveBar from "./LiveBar";
import "./styles/Header.css";

const transitionAuto = require("@alexspirgel/transition-auto");

export default function Header() {
  const path = window.location.pathname.replaceAll("/", "");
  const root = path === "" || path === "about" || path === "home";
  const media = path.includes("media");
  const [liveLink] = useState(null);

  function aboutTab() {
    const about = document.querySelector("#abt-about");
    const aboutButtons = document.querySelectorAll(".abt-nav button");
    const resume = document.querySelectorAll(".text-about");
    const testimonials = document.querySelectorAll(".text-test");
    const faq = document.querySelectorAll(".text-faq");

    if (window.location.pathname === "/") {
      if (!about.classList.contains("abt-active")) {
        aboutButtons.forEach((el) => el.classList.remove("abt-active"));
        about.classList.add("abt-active");

        aboutTransition();

        setTimeout(() => {
          resume.forEach((el) => (el.style.display = "block"));
          testimonials.forEach((el) => (el.style.display = "none"));
          faq.forEach((el) => (el.style.display = "none"));
        }, 550);
      }

      function aboutTransition() {
        transitionAuto({
          element: document.querySelector(".outer"),
          property: "height",
          value: 0,
        });

        setTimeout(() => {
          transitionAuto({
            element: document.querySelector(".outer"),
            property: "height",
            value: "auto",
          });
        }, 600);
      }
    }
  }

  return (
    <header style={liveLink ? {marginTop: "40px"} : null}>
      <LiveBar page={liveLink} />
      <div className="header-left">
        <div>
          {/* eslint-disable-next-line */}
          <a
            href="/"
            target={media ? "_blank" : "_self"}
            rel="noreferrer"
            onClick={root ? (e) => {
              e.preventDefault();
              document.querySelector("#root").scrollIntoView();
              window.history.replaceState("page", "", "/");
            } : null}
          >
            <img
              src="/images/header-jvk-logo.png"
              alt="JVK"
              draggable="false"
            />
          </a>
        </div>
      </div>
      <div className="header-right">
        <div className="links-left">
          <div className="links">
            {/* eslint-disable-next-line */}
            <a
              href="/"
              target={media ? "_blank" : "_self"}
              rel="noreferrer"
              onClick={root ? (e) => {
                e.preventDefault();
                document.querySelector("#root").scrollIntoView();
                window.history.replaceState("page", "", "/");
                aboutTab();
              } : aboutTab}
            >
              HOME
            </a>
          </div>
          <div className="links">
            <a
              href="/about?=main"
              target={media ? "_blank" : "_self"}
              rel="noreferrer"
              onClick={root ? (e) => {
                e.preventDefault();
                document.querySelector("#about").scrollIntoView();
                window.history.replaceState("page", "", "/about?=main");
              } : null}
            >
              ABOUT
            </a>
          </div>
          <div className="links">
            <a
              href="/media"
              target={media ? "_self" : "_blank"}
              rel="noreferrer"
              className="mediahl"
            >
              MEDIA
            </a>
            <div className="mediamo">
              <div className="media-menu">
                <a
                  className="media-dd"
                  href="/media?=latest"
                  target={media ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                >
                  <div>LATEST RELEASES</div>
                </a>
                <a
                  className="media-dd"
                  href="/media?=albums"
                  target={media ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                >
                  <div>ALBUMS</div>
                </a>
                <a
                  className="media-dd"
                  href="/media?=gallery"
                  target={media ? "_self" : "_blank"}
                  rel="noopener noreferrer"
                >
                  <div>OTHER</div>
                </a>
                <a
                  className="media-dd"
                  href="https://www.imdb.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>RESUME</div>
                </a>
              </div>
            </div>
          </div>
          <div className="links">
            <a
              href="https://thekompletestore.com"
              target="_blank"
              rel="noreferrer"
            >
              STORE
            </a>
          </div>
          <div className="links">
            <a
              href="/home?=contact"
              target={media ? "_blank" : "_self"}
              rel="noopener noreferrer"
              onClick={root ? (e) => {
                e.preventDefault();
                document.querySelector("#contact").scrollIntoView();
                window.history.replaceState("page", "", "/home?=contact");
              } : null}
            >
              CONTACT
            </a>
          </div>
        </div>
        <div className="links-right">
          {/*<img src="/images/media-icon-cart.png" alt="Cart icon" draggable="false" />*/}
          <a
            href="https://paypal.me/JVKStudios"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#808080" }}
          >
            DONATE
          </a>
          <div
            style={{
              height: "1px",
              width: "55px",
              backgroundColor: "#505050",
              margin: "6px 0 4px 0",
            }}
          ></div>
          <a href="#root" style={{ color: "#808080" }}>
            VIEW CART
          </a>
        </div>
      </div>
    </header>
  );
}
