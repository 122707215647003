import React, { useState } from "react";
import "./styles/Details.css";

export default function Details({
  albums,
  allTracks,
  allVideos,
  id,
  viewAlbum,
  toMinutes,
}) {
  const albumData = albums.filter((album) => album.id === id)[0];
  const tracks = allTracks.filter((track) => track.album === id);
  const videos = allVideos.filter((video) => video.album === id);
  const tracksLength = tracks.reduce((a, b) => a + b.duration, 0);
  const videosLength = videos.reduce((a, b) => a + b.duration, 0);
  const currentIndex = albums.findIndex(album => album.id === id);

  function previous() {
    if (currentIndex > 0) {
      viewAlbum(currentIndex - 1);
    }
  }

  function next() {
    if (currentIndex < (albums.length - 1)) {
      viewAlbum(currentIndex + 1);
    }
  }

  function date() {
    const dateString = new Date(albumData.date);
    const dateDay = dateString.getDate();
    const day =
      dateDay.toString() +
      (dateDay === 1 || dateDay === 21 || dateDay === 31
        ? "st"
        : dateDay === 2 || dateDay === 22
        ? "nd"
        : dateDay === 3 || dateDay === 23
        ? "rd"
        : "th");
    const month = dateString.toLocaleString("default", { month: "long" });
    const year = dateString.getFullYear().toString();

    return `${month} ${day}, ${year}`.toUpperCase();
  }

  const [userRating, setUserRating] = useState("UNRATED");

  function like() {
    if (userRating === "POSITIVE") {
      setUserRating("UNRATED");
    } else {
      setUserRating("POSITIVE");
    }
  }

  function dislike() {
    if (userRating === "NEGATIVE") {
      setUserRating("UNRATED");
    } else {
      setUserRating("NEGATIVE");
    }
  }

  return (
    <div className="album-view" style={{"--accent-color": albumData.color}}>
      <button className="arrow left" onClick={previous}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="66"
          viewBox="0 0 35 66"
          fill="none"
          stroke="#505050"
          style={currentIndex <= 0 ? {stroke: "#363636"} : {}}
        >
          <path d="M34 65L2 33L34 1" strokeWidth="2" />
        </svg>
      </button>
      <img
        src={albumData.imagePath}
        alt={albumData.title}
        width="292"
        height="438"
        draggable="false"
      />
      <div>
        <h3>{albumData.title}</h3>
        <h6>{albumData.composer}</h6>
        <p className="about">{albumData.about}</p>
        <p className="details">
          {`${tracks.length} TRACK${tracks.length === 1 ? "" : "S"}`}
          &nbsp;&nbsp;<b>•</b>&nbsp;&nbsp;
          {toMinutes(tracksLength)}
        </p>
        <p className="details">
          {`${videos.length} VIDEO${videos.length === 1 ? "" : "S"}`}
          &nbsp;&nbsp;<b>•</b>&nbsp;&nbsp;
          {toMinutes(videosLength)}
        </p>
        <p className="details">
          {date()}
          &nbsp;&nbsp;<b>•</b>&nbsp;&nbsp;
          {albumData.views.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          &nbsp; VIEWS
        </p>
        <p className="copyright">{albumData.copyright}</p>
        <button className="play">
          <svg width="11" height="14" viewBox="0 0 11 14" fill="currentColor">
            <path d="M5 3.18L0 0V14L5 10.82Z" />
            <path d="M11 7L5 3.18V10.82L11 7Z" />
          </svg>
          PLAY ALBUM
        </button>
        <div className="buttons">
          <button>
            <svg width="16" height="14" viewBox="0 0 21 19" fill="currentColor">
              <path d="M19.0909 0H1.90909C0.859091 0 0 0.855 0 1.9V13.3C0 14.345 0.859091 15.2 1.90909 15.2H8.59091V17.1H6.68182V19H14.3182V17.1H12.4091V15.2H19.0909C20.1409 15.2 21 14.345 21 13.3V1.9C21 0.855 20.1409 0 19.0909 0ZM19.0909 13.3H1.90909V1.9H19.0909V13.3Z" />
            </svg>
            VIDEOS
          </button>
          <button>
            <svg width="9" height="16" viewBox="0 0 9 16" fill="currentColor">
              <path d="M4.84479 7.02222C2.83792 6.49778 2.19254 5.95556 2.19254 5.11111C2.19254 4.14222 3.08546 3.46667 4.57956 3.46667C6.15324 3.46667 6.73674 4.22222 6.78978 5.33333H8.74361C8.68173 3.80444 7.31139 2.23111 5.46365 1.77778V0H3.69548V1.77778C1.98035 2.15111 0.159136 3.41333 0.159136 5.12889C0.159136 7.18222 1.84774 8.20444 4.31434 8.8C6.52456 9.33333 6.9666 10.1156 6.9666 10.9422C6.9666 11.5556 6.5334 12.5333 4.57956 12.5333C2.75835 12.5333 2.04224 11.7156 1.94499 10.6667H0C0.10609 12.6133 1.99803 13.8578 3.69548 14.2222V16H5.46365V14.2222C7.18762 13.8933 9 12.7556 9 10.9333C9 8.40889 6.85167 7.54667 4.84479 7.02222Z" />
            </svg>
            PURCHASE
          </button>
          <button>
            <svg width="12" height="14" viewBox="0 0 15 16" fill="currentColor">
              <path d="M12.0482 11.3092C11.4378 11.3092 10.8916 11.5502 10.4739 11.9277L4.74699 8.59438C4.78715 8.40964 4.81928 8.2249 4.81928 8.03213C4.81928 7.83936 4.78715 7.65462 4.74699 7.46988L10.4096 4.16867C10.8434 4.57028 11.4137 4.81928 12.0482 4.81928C13.3815 4.81928 14.4578 3.74297 14.4578 2.40964C14.4578 1.07631 13.3815 0 12.0482 0C10.7149 0 9.63855 1.07631 9.63855 2.40964C9.63855 2.60241 9.67068 2.78715 9.71084 2.97189L4.04819 6.27309C3.61446 5.87149 3.04418 5.62249 2.40964 5.62249C1.07631 5.62249 0 6.69879 0 8.03213C0 9.36546 1.07631 10.4418 2.40964 10.4418C3.04418 10.4418 3.61446 10.1928 4.04819 9.79116L9.76707 13.1325C9.72691 13.3012 9.70281 13.4779 9.70281 13.6546C9.70281 14.9478 10.755 16 12.0482 16C13.3414 16 14.3936 14.9478 14.3936 13.6546C14.3936 12.3614 13.3414 11.3092 12.0482 11.3092Z" />
            </svg>
            SHARE
          </button>
          <div
            style={{
              width: "1px",
              height: "40px",
              margin: "35px 20px -5px 10px",
              backgroundColor: "#808080",
            }}
          ></div>
          <button
            onClick={like}
            style={
              userRating === "POSITIVE" ? { backgroundColor: "#3a4b5b" } : {}
            }
          >
            <svg width="16" height="14" viewBox="0 0 16 14" fill="currentColor">
              <path d="M9.58 0L4.36 5V14H13.36L16 8V4.9H10L10.81 1.13L9.58 0ZM0 5.6H2.91V14H0V5.6Z" />
            </svg>
            LIKE
          </button>
          <button
            onClick={dislike}
            style={
              userRating === "NEGATIVE" ? { backgroundColor: "#5b3a3a" } : {}
            }
          >
            <svg width="16" height="14" viewBox="0 0 16 14" fill="currentColor">
              <path d="M6.42 14L11.64 9V0H2.64L0 6V9.1H6L5.19 12.87L6.42 14ZM13.09 0H16V8.4H13.09V0Z" />
            </svg>
            DISLIKE
          </button>
        </div>
      </div>
      <button className="arrow right" onClick={next}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="66"
          viewBox="0 0 35 66"
          fill="none"
          stroke="#505050"
          style={currentIndex >= (albums.length - 1) ? {stroke: "#363636"} : {}}
        >
          <path d="M1 1L33 33L1 65" strokeWidth="2" />
        </svg>
      </button>
    </div>
  );
}
