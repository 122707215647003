import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import MediaCarousel from "./MediaCarousel.jsx";
import Video from "./Video.jsx";
import "./styles/NextVideos.css";

export default function NextVideos({
  albums,
  setPlayingType,
  setVideoM,
  setPlaying,
  videos,
  index,
  setIndex,
  toMinutes,
  viewAlbum,
}) {
  const nextVideos = videos.filter(video => (video !== videos[index]));
  const sortedVideos = [];

  for (let i = index === nextVideos.length ? 0 : index + 1; i !== index; i === nextVideos.length ? i = 0 : i++) {
    const video = videos[i];
    sortedVideos.push(
      <SplideSlide>
        <Video
          album={albums.filter(album => (album.id === video.album))[0]}
          setPlayingType={setPlayingType}
          setVideoM={setVideoM}
          setPlaying={setPlaying}
          id={`next_video_${i}`}
          videos={videos}
          playingIndex={index}
          index={i}
          setIndex={setIndex}
          name={video.title}
          image={video.thumbnail}
          preview={video.filePath}
          toMinutes={toMinutes}
          viewAlbum={() => viewAlbum(video.album)}
        />
      </SplideSlide>
    );
  }

  return(
    <div id="next-videos">
      <MediaCarousel>
        {sortedVideos}
      </MediaCarousel>
    </div>
  );
}
