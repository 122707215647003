import React from "react";
import MediaCarousel from "../MediaCarousel.jsx";
import { SplideSlide } from "@splidejs/react-splide";
import Image from "../Image.jsx";
import MediaHeading from "../MediaHeading.jsx";
import "./styles/Images.css";

export default function Images({ images, albums, viewAlbum }) {
  return (
    <div id="album-images">
      <MediaHeading link={{ url: "#album-images", text: "VIEW ALL" }}>
        GALLERY
      </MediaHeading>
      <div className="image-slider">
        {images[0] !== "IMAGES_NOT_YET_LOADED" &&
        albums[0] !== "ALBUMS_NOT_YET_LOADED" &&
        images.length !== 0 ? (
          <MediaCarousel>
            {images.map((image, index) => (
              <SplideSlide>
                <Image
                  id={image.id}
                  title={image.title}
                  album={image.album}
                  thumbnail={image.thumbnail}
                  albums={albums}
                  viewAlbum={viewAlbum}
                  index={index}
                  length={images.length}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
        ) : (
          <span>loading...</span>
        )}
      </div>
    </div>
  );
}