import React, { useEffect } from "react";
import "./styles/MediaTabs.css";

//const transitionAuto = require("@alexspirgel/transition-auto");

export default function MediaTabs({path, currentMedia, setCurrentMedia, children}) {
  /*function aboutTransition() {
    transitionAuto({
      element: document.querySelector(".outer"),
      property: "height",
      value: 0
    });

    setTimeout(() => {
      transitionAuto({
        element: document.querySelector(".outer"),
        property: "height",
        value: "auto"
      });
    }, 600);
  }*/

  useEffect(() => {
    switch (path) {
      case "latest":
        setCurrentMedia("latest");
        break;
      case "albums":
        setCurrentMedia("albums");
        break;
      case "gallery":
        setCurrentMedia("gallery");
        break;
      default:
        break;
    }
  // eslint-disable-next-line
  }, []);

  return(
    <>
      <div className="media-nav">
        <a href="/media?=latest" onClick={(e) => e.preventDefault()}>
          <button
            onClick={() => {
              setCurrentMedia("latest");
              window.history.replaceState("", "", "/media?=latest");
            }}
            id="media-latest"
            className={ currentMedia === "latest" ? "media-active" : "" }
          >
            LATEST RELEASES
          </button>
        </a>
        <a href="/media?=albums" onClick={(e) => e.preventDefault()}>
          <button
            onClick={() => {
              setCurrentMedia("albums");
              window.history.replaceState("", "", "/media?=albums");
            }}
            id="media-albums"
            className={ currentMedia === "albums" ? "media-active" : "" }
          >
            ALBUMS
          </button>
        </a>
        <a href="/media?=gallery" onClick={(e) => e.preventDefault()}>
          <button
            onClick={() => {
              setCurrentMedia("gallery");
              window.history.replaceState("", "", "/media?=gallery");
            }}
            id="media-gallery"
            className={ currentMedia === "gallery" ? "media-active" : "" }
          >
            OTHER
          </button>
        </a>
        <a
          href="https://imdb.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{display: "contents"}}
        >
          <button id="media-personal" className="other">
            RESUME
          </button>
        </a>
      </div>
      <div className="outer">
        <div className="media-main inner">
          <div className="media-content">
            { children }
          </div>
        </div>
      </div>
    </>
  );
}

