import React from "react";
import MediaHeading from "../MediaHeading";
import { AudioCollection, AudioTrack } from "../AudioCollection";
import "./styles/Tracks.css";

export default function Tracks({
  id,
  albums,
  tracks,
  toMinutes,
  currentTime,
  openPlayer,
  playing,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  setList,
  viewAlbum,
}) {
  return (
    <section id="album-tracks">
      <MediaHeading link={{ url: "#album-tracks", text: "VIEW ALL" }}>
        AUDIO TRACKS
      </MediaHeading>
      <AudioCollection>
        {tracks[0] !== "TRACKS_NOT_YET_LOADED" &&
        albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
          tracks
            .filter((track) => track.album === id)
            .map((track, index) => (
              <AudioTrack
                key={track.id}
                id={track.id}
                index={index + 1}
                title={track.title}
                album={albums[track.album].title}
                mood={track.mood}
                duration={[
                  currentPlaying === track.id
                    ? toMinutes(currentTime)
                    : toMinutes(0),
                  toMinutes(track.duration),
                ].join(" / ")}
                rating={{ positive: 127, negative: 21 }}
                openPlayer={openPlayer}
                playing={playing}
                setPlaying={setPlaying}
                currentPlaying={currentPlaying}
                setCurrentPlaying={setCurrentPlaying}
                list={tracks
                  .filter((track) => track.album === id)
                  .map((track) => track.id)}
                setList={setList}
                tracks={tracks}
                viewAlbum={() => viewAlbum(track.album)}
                date={track.date}
                views={track.views}
                description={track.description}
                copyright={track.copyright}
              />
            ))
        ) : (
          <>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
            <tr className="loading-track">
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
              <td>
                <div className="loading-data"></div>
              </td>
            </tr>
          </>
        )}
      </AudioCollection>
    </section>
  );
}