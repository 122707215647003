import React from "react";
import "./styles/MediaHeading.css";

export default function MediaHeading({ link, children }) {
  return(
    <div className="heading">
      <h3>{ children }</h3>
      <span>
        <a href={link.url}>{link.text}</a>
      </span>
    </div>
  );
}
