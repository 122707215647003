import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import MediaCarousel from "./MediaCarousel.jsx";
import Album from "./Album.jsx";
import "./styles/LatestAlbums.css";

export default function LatestAlbums({ albums, setAlbumView, toMinutes, tracks }) {
  return (
    <div id="latest-albums">
      {albums[0] !== "ALBUMS_NOT_YET_LOADED" ? (
        <>
          <h2 className="subtitle">PERSONAL</h2>
          <MediaCarousel>
            {albums.map((album) => (
              <SplideSlide>
                <Album
                  id={album.id}
                  name={album.title}
                  image={album.mediumImagePath}
                  composer={album.composer}
                  height="small"
                  viewAlbum={() => setAlbumView(album.id)}
                  toMinutes={toMinutes}
                  tracks={tracks}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
          <h2 className="subtitle">PROJECTS</h2>
          <MediaCarousel>
            {albums.map((album) => (
              <SplideSlide>
                <Album
                  id={album.id}
                  name={album.title}
                  image={album.imagePath}
                  composer={album.composer}
                  height="big"
                  viewAlbum={() => setAlbumView(album.id)}
                  toMinutes={toMinutes}
                  tracks={tracks}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
        </>
      ) : (
        <div className="album-loader">
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
          <div className="loading-album">
            <div className="loading-album_image"></div>
            <div className="loading-album_title"></div>
            <div className="loading-album_composer"></div>
          </div>
        </div>
      )}
    </div>
  );
}
