import React, { useEffect } from "react";
import Header from "../components/Header";
import Slider from "../components/Slider";
import About from "../components/About";
import Contact from "../components/Contact";
import Connect from "../components/Connect";
import Footer from "../components/Footer";

export default function Home({ path }) {
  useEffect(() => {
    if (path === "contact") {
      document.querySelector("#contact").scrollIntoView();
    }
  }, [path]);
  return(
    <>
      <Header />
      <Slider />
      <About path={path === "" ? null : path} />
      <Contact />
      <Connect />
      <Footer />
    </>
  );
}
