import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import "./styles/Testimonial.css";

export default function Tesimonial({ name, email, occupation, date, children }) {
  return (
    <SplideSlide>
      <div className="testimonial">
        <div className="testimonial-text">
          <img
            src="/images/about-testimonials-quote.png"
            alt="quote"
            width="16"
            height="16"
          />
          {children}
          <img
            src="/images/about-testimonials-quote.png"
            alt="quote"
            width="16"
            height="16"
          />
          <span className="testimonial-date">{date}</span>
        </div>
        <a href={`mailto:${email}`} className="testimonial-name">{name}</a>
        <span className="testimonial-title">{occupation}</span>
        <div className="testimonial-progress"></div>
      </div>
    </SplideSlide>
  );
}
