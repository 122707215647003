import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./styles/Contact.css";

const reason = [
  {value: "GENERAL", label: "GENERAL"},
  {value: "HIRING", label: "HIRING"},
  {value: "LICENSING", label: "LICENSING"},
  {value: "WEBSITE", label: "WEBSITE"},
  {value: "OTHER", label: "OTHER"}
];

const subjectOptions = [
  [
    {value: "QUESTION", label: "QUESTION"},
    {value: "PURCHASES", label: "PURCHASES"},
    {value: "FEEDBACK", label: "FEEDBACK"}
  ],
  [
    {value: "SHORT", label: "SHORT"},
    {value: "SHOW", label: "SHOW"},
    {value: "MOVIE", label: "MOVIE"},
    {value: "OTHER", label: "OTHER"}
  ],
  [
    {value: "ALBUM", label: "ALBUM"},
    {value: "TRACK", label: "TRACK"},
    {value: "OTHER", label: "OTHER"}
  ],
  [
    {value: "REPORT A BUG", label: "REPORT A BUG"},
    {value: "ADD FEATURE", label: "ADD FEATURE"},
    {value: "OTHER", label: "OTHER"}
  ],
  [
    {value: "UNLISTED", label: "UNLISTED"}
  ]
];

export default function Contact() {
  const [subject, setSubject] = useState(subjectOptions[0]);
  const [disabled, setDisabled] = useState(true);
  const [inputChecked, setInputChecked] = useState(false);

  //if errors are found, create error message
  function checkinput() {
    setInputChecked(true);
    
    let errormessage = "<b>INPUT REQUIRED:</b>";
    document.querySelectorAll("#contact input, #contact textarea").forEach(item => {
      item.addEventListener("invalid", (e) => e.preventDefault());
      let length = item.value.length;
      switch (item.getAttribute("name")) {
        case "fullname":
          if (length < 5 || length > 30) {
            item.style.borderColor = "#ca0000";
            if (length === 0) {
              errormessage += " NAME;";
            } else if (length < 5) {
              errormessage += " NAME (5 CHARACTER LIMIT REQUIRED);";
            } else {
              errormessage += " NAME (30 CHARACTER LIMIT REACHED);";
            }
          } else {
            item.style.borderColor = "#e9ecef";
          }
          break;
        case "email":
          if (!item.checkValidity() || length > 40 || length < 6) {
            item.style.borderColor = "#ca0000";
            if (length < 6) {
              errormessage += " EMAIL (6 CHARACTER LIMIT REQUIRED);";
            } else if (length <= 40) {
              errormessage += " EMAIL;";
            } else {
              errormessage += " EMAIL (40 CHARACTER LIMIT REACHED);";
            }
          } else {
            item.style.borderColor = "#e9ecef";
          }
          break;
        case "reason":
          if (!item.checkValidity()) {
            document.querySelector("#reason").style.borderColor = "#ca0000";
            errormessage += " REASON;";
          } else {
            document.querySelector("#reason").style.borderColor = "#e9ecef";
          }
          break;
        case "selector1":
          if (!item.checkValidity()) {
            document.querySelector("#sel").style.borderColor = "#ca0000";
            errormessage += " SUBJECT;";
          } else {
            document.querySelector("#sel").style.borderColor = "#e9ecef";
          }
          break;
        case "message":
          if (length < 50 || length > 2000) {
            item.style.borderColor = "#ca0000";
            if (length === 0) {
              errormessage += " MESSAGE;";
            } else if (length < 50) {
              errormessage += " MESSAGE (50 CHARACTER LIMIT REQUIRED);";
            } else {
              errormessage += " MESSAGE (2000 CHARACTER LIMIT REACHED);";
            }
          } else if (length === 2000) {
            item.style.borderColor = "#ca0000";
          } else {
            item.style.borderColor = "#e9ecef";
          }
          break;
        default:
          break;
      }
    });

    if (errormessage === "<b>INPUT REQUIRED:</b>") {
      document.querySelector("#errormessage").innerHTML = "";
      document.querySelectorAll(".inputbox").forEach(el => el.style.borderColor = "#e9ecef");
    } else {
      document.querySelector("#errormessage").innerHTML = errormessage.replace(/;([^;]*)$/, '.');
    }
  }

  useEffect(() => {
    const messagebox = document.querySelector("#message");
    const namebox = document.querySelector("#fullname");
    const emailbox = document.querySelector("#email");

    messagebox.addEventListener("input", () => {
      const length = messagebox.value.length;
      document.querySelector("#message-count").innerHTML = length;
      if (length >= 2000 || length < 50) {
        document.querySelector("#message-count").parentElement.style.color = "#ca0000";
      } else {
        document.querySelector("#message-count").parentElement.style.color = "#437cb2";
      }
    });
    
    messagebox.addEventListener("focus", () => {
      document.querySelector("#message-count").parentElement.style.display = "inline";
    });
    
    messagebox.addEventListener("blur", () => {
      document.querySelector("#message-count").parentElement.style.display = "none";
    });

    namebox.addEventListener("input", () => {
      const length = namebox.value.length;
      document.querySelector("label[for=fullname] > span").innerHTML = length;
      if (length > 30 || length < 5) {
        document.querySelector("label[for=fullname]").style.color = "#ca0000";
      } else {
        document.querySelector("label[for=fullname]").style.color = "#437cb2";
      }
    });
    
    namebox.addEventListener("focus", () => {
      document.querySelector("label[for=fullname]").style.display = "inline";
    });
    
    namebox.addEventListener("blur", () => {
      document.querySelector("label[for=fullname]").style.display = "none";
    });

    emailbox.addEventListener("input", () => {
      const length = emailbox.value.length;
      document.querySelector("label[for=email] > span").innerHTML = length;
      if (length > 40 || length < 6) {
        document.querySelector("label[for=email]").style.color = "#ca0000";
      } else {
        document.querySelector("label[for=email]").style.color = "#437cb2";
      }
    });
    
    emailbox.addEventListener("focus", () => {
      document.querySelector("label[for=email]").style.display = "inline";
    });
    
    emailbox.addEventListener("blur", () => {
      document.querySelector("label[for=email]").style.display = "none";
    });

    document.querySelector(".submit").onclick = function() {
      document.querySelector(".contact-button").click();
    }

    //check for errors when submitted, and add red outlines if errors are found
    document.querySelector(".contact-button").onclick = function() {
      document.querySelectorAll(".inputbox").forEach(el => el.classList.add("inputboxcheck"));
    
      document.querySelectorAll(".inputbox").forEach(el => el.addEventListener("input", function() {
        checkinput();
      }));

      document.querySelector("#reason").addEventListener("change", function() {
        checkinput();
      });
    
      checkinput();
    }

    //
    // TODO: document this better so that I don't forget
    //
    document.getElementById("contactform").onsubmit = async function(e) {
      e.preventDefault();
      if (messagebox.value.length <= 2000 && messagebox.value.length >= 50 && namebox.value.length <= 30 && namebox.value.length >= 5 && emailbox.value.length <= 50 && emailbox.value.length >= 6) {

        //animation
        document.querySelector(".contact-button").setAttribute("disabled", true);  //disable button to prevent submitting twice
        document.querySelectorAll(".inputbox").forEach(el => el.classList.remove("inputboxcheck"));
        let name = document.querySelector("#fullname").value;
        let email = document.querySelector("#email").value;
        let reason = document.querySelector("input[name='reason']").value;
        let subject = document.querySelector("input[name='selector1']").value;
        let message = document.querySelector("#message").value;

        let button = document.querySelector(".contact-button");
        let result = document.querySelector(".result-contact-inner");

        //animation effect all
        document.querySelector(".submit").style.paddingLeft = "66px";

        setTimeout(function() {
          button.style.transition = "0.75s cubic-bezier(0.77, 0, 0.175, 1)";
          let scaleY = 342 / 28;                   // expanded height/button height
          //let originY = 28 - (4 / (342 / 28));
          let scaleX = 918 / 65;                   // expanded width/button width
          //let originX = 65 - ((65 / 820) * 4); 
          button.style.transformOrigin = "64.6556px 27.6448px";
          button.style.transform = "scale(" + scaleX + ", " + scaleY + ")";

          setTimeout(function() {
            result.style.width = "100%";
            document.querySelector("#sendingcontact").style.visibility = "visible";
          }, 750);
        }, 200);

        function error() {
          //animation effect error
          const check = setInterval(function() {
            if (result.clientWidth === document.querySelector(".result-contact").clientWidth) {
              result.style.transition = "1s";

              clearInterval(check);

              result.style.top = "-342px";
              result.style.backgroundColor = "#FF4546";
              result.style.color = "#ffffff";

              result.innerHTML = "<svg width='10' height='10' class='x' stroke='currentColor' fill='none' style='margin-right: 0; margin-bottom: 7px;'><polyline points='0 0 10 10 5 5 10 0 0 10'></polyline></svg>AN ERROR OCCURED!";

              setTimeout(function() {
                button.style.transform = "scale(1, 1)";
                document.querySelector("#sendingcontact").style.visibility = "hidden";
                document.querySelector(".submit").style.paddingLeft = "0";
              }, 1000)

              setTimeout(function() {
                result.style.top = "0";
                result.style.color = "#262626";

                setTimeout(function() {
                  document.querySelector(".contact-button").removeAttribute("disabled");

                  result.style.width = "0";
                  result.style.transition = "1s";

                  button.style.transition = "0.8s ease";

                  setTimeout(function() {
                    result.style.top = "-10px";
                    result.style.backgroundColor = "#ffffff";
                  }, 750)
                }, 1000)
              }, 1500)
            }
          }, 10);
        }

        fetch("/api/contact", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: name,
            email: email,
            reason: reason,
            subject: subject,
            message: message
          })
        })
        .then(async res => {
          if (res.status === 200) {
            //animation effect success
            const check = setInterval(function() {
              if (result.clientWidth === document.querySelector(".result-contact").clientWidth) {
                document.querySelector("#fullname").value = "";
                document.querySelector("#email").value = "";
                document.querySelector("#reason").value = "";
                document.querySelector("#sel").value = "";
                document.querySelector("#message").value = "";
                document.querySelector("#fullname").blur();
                document.querySelector("#email").blur();
                document.querySelector("#reason").blur();
                document.querySelector("#sel").blur();
                document.querySelector("#message").blur();

                result.style.transition = "1s";

                clearInterval(check);

                result.style.top = "-342px";
                result.style.backgroundColor = "#d4edda";
                result.style.color = "#ffffff";

                result.innerHTML = "MESSAGE SENT!<svg width='12' height='10' class='check' stroke='currentColor' fill='none' style='margin-right: 0; margin-top: 8px;'><polyline points='1 5.5 5 9.5 12 1.5'></polyline></svg>";

                setTimeout(function() {
                  button.style.transform = "scale(1, 1)";
                  document.querySelector("#sendingcontact").style.visibility = "hidden";
                  document.querySelector(".submit").style.paddingLeft = "0";
                }, 1000)

                setTimeout(function() {
                  result.style.top = "0";
                  result.style.color = "#262626";

                  setTimeout(function() {
                    document.querySelector(".contact-button").removeAttribute("disabled");

                    result.style.width = "0";
                    result.style.transition = "1s";

                    button.style.transition = "0.8s ease";

                    setTimeout(function() {
                      result.style.top = "-10px";
                      result.style.backgroundColor = "#ffffff";
                    }, 750)
                  }, 1000)
                }, 1500)
              }
            }, 10);
          } else {
            error();
          }
        })
        .catch(async err => {
          console.log(err);
          error();
        });
      }
    }
  });

  return(
    <section id="contact">
      <h1>CONTACT</h1>
      <div className="contact-main">
        <div className="contact-desc">
          <h5>CONTACT INFORMATION</h5>
          <div className="box">
            <div className="icon">
              <img src="images/contact-icon-location.png" width="18px" height="24px" alt="address icon" />
            </div>
            <div className="details">
              <h6>ADDRESS</h6>
              <p>JVK STUDIOS</p>
              <p>PO BOX AVAILABLE SOON</p>
              <p>CITY / STATE AVAILABLE SOON</p>
            </div>
          </div>
          <div className="box">
            <div className="icon">
              <img src="images/contact-icon-email.png" width="24px" height="16px" alt="email icon" />
            </div>
            <div className="details">
              <h6>GENERAL INQUIRIES</h6>
              <p><a href="mailto:support@jvkstudios.com">SUPPORT@JVKSTUDIOS.COM</a></p>
            </div>
          </div>
          <div className="box">
            <div className="icon">
              <img src="images/contact-icon-email.png" width="24px" height="16px" alt="email icon" />
            </div>
            <div className="details">
              <h6>BUSINESS INQUIRIES</h6>
              <p><a href="mailto:support@jvkstudios.com">SUPPORT@JVKSTUDIOS.COM</a></p>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="contact-wrapper">
            <div className="contact-content">
              <div className="contentbg">
                <div id="contactl" className="content">
                  <div style={{ height: "411px", position: "relative" }}>
                    <span>FOR ALL INQUIRIES PLEASE FILL OUT THIS FORM AND LET ME KNOW YOUR THOUGHTS. I WILL RESPOND AS SOON AS POSSIBLE. FOLLOW <a href="https://discord.com">DISCORD</a>.</span>
                    <form name="contactform" id="contactform" method="post" action="">
                      <label style={{ right: "474px" }} htmlFor="fullname"><span>0</span>&nbsp;/&nbsp;30</label>
                      <input type="text" name="fullname" id="fullname" className="inputbox" placeholder="NAME" required />
                      <label style={{ right: "10px" }} htmlFor="email"><span>0</span>&nbsp;/&nbsp;40</label>
                      <input type="email" name="email" id="email" className="inputbox" placeholder="EMAIL" required />
                      <Select
                        options={ reason }
                        defaultValue={ null }
                        placeholder="REASON"
                        name="reason"
                        id="reason"
                        className="inputbox"
                        required={ true }
                        unstyled={ true }
                        isSearchable={ false }
                        onChange={(selectedOption) => {
                          setDisabled(false)
                          switch (selectedOption.value) {
                            case "GENERAL":
                              setSubject(subjectOptions[0]);
                              break;
                            case "HIRING":
                              setSubject(subjectOptions[1]);
                              break;
                            case "LICENSING":
                              setSubject(subjectOptions[2]);
                              break;
                            case "WEBSITE":
                              setSubject(subjectOptions[3]);
                              break;
                            case "OTHER":
                              setSubject(subjectOptions[4]);
                              break;
                            default:
                              break;
                          }
                          if (inputChecked) setTimeout(() => checkinput(), 50);
                        }}
                      />
                      <Select
                        options={ subject }
                        defaultValue={ null }
                        placeholder="SUBJECT"
                        name="selector1"
                        id="sel"
                        className="inputbox"
                        required={ true }
                        unstyled={ true }
                        isSearchable={ false }
                        isDisabled={ disabled }
                        onChange={() => {
                          if (inputChecked) setTimeout(() => checkinput(), 50);
                        }}
                      />
                      <textarea name="message" id="message" className="inputbox" placeholder="MESSAGE" maxLength="2000" style={{ width: "100%", marginBottom: "0", height: "258px", boxSizing: "border-box", padding: "8px 10px 39px", resize: "none" }} required></textarea>
                      <input type="submit" className="contact-button" id="contactID" value="" />
                      <div className="submit">
                        <span className="submit-inner1">SUBMIT</span>
                        <span className="submit-inner2">SUBMIT</span>
                      </div>
                      <span id="sendingcontact">SENDING...</span>
                      <div className="result-contact">
                        <div className="result-contact-inner"></div>
                      </div>
                      <div className="h-over"></div>
                    </form>
                    <span id="errormessage"></span>
                    <span style={{ fontFamily: "Arial, Helvetica, sans-serif", fontSize: "12px", display: "none", position: "absolute", zIndex: 102, right: 0, bottom: "16px", color: "#ca0000" }}><span id="message-count">0</span>&nbsp;/&nbsp;2000</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
