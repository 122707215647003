import React, { useEffect, useState } from "react";
import Testimonials from "./Testimonials";
import "./styles/About.css";
import FAQs from "./FAQs";

const transitionAuto = require("@alexspirgel/transition-auto");

export default function About({ path }) {
  const [testimonialsActive, setTestimonialsActive] = useState(false);

  useEffect(() => {
    let p = 0;
    let num = 1;
    let wait = false;
    const body = document.body;
    const galleryviewbox = document.querySelectorAll(".galleryviewbox");
    const galleryexit = document.querySelector("#galleryexit");
    const leftarrow = document.querySelector("#leftarrow");
    const rightarrow = document.querySelector("#rightarrow");
    const galleryview = document.querySelector("#galleryview");
    const about1 = document.querySelector("#about1");
    const galleryviewcont = document.querySelector("#galleryviewcont");
    const abtAbout = document.querySelector("#abt-about");
    const abtFaq = document.querySelector("#abt-faq");
    const abtTest = document.querySelector("#abt-test");
    const abtNav_button = document.querySelectorAll(".abt-nav button");
    const textAbout = document.querySelectorAll(".text-about");
    const textTest = document.querySelectorAll(".text-test");
    const textFaq = document.querySelectorAll(".text-faq");
    const outer = document.querySelector(".outer");

    // load about section depending on url parameters
    function page() {
      if (path === "main") {
        document.querySelector("#about").scrollIntoView();
        abtNav_button.forEach((el) => el.classList.remove("abt-active"));
        abtAbout.classList.add("abt-active");
        textAbout.forEach((el) => (el.style.display = "block"));
        textTest.forEach((el) => (el.style.display = "none"));
        textFaq.forEach((el) => (el.style.display = "none"));
        setTestimonialsActive(false);
      } else if (path === "testimonials") {
        document.querySelector("#about").scrollIntoView();
        abtNav_button.forEach((el) => el.classList.remove("abt-active"));
        abtTest.classList.add("abt-active");
        textAbout.forEach((el) => (el.style.display = "none"));
        textTest.forEach((el) => (el.style.display = "flex"));
        textFaq.forEach((el) => (el.style.display = "none"));
        setTestimonialsActive(true);
      } else if (path === "faq") {
        document.querySelector("#about").scrollIntoView();
        abtNav_button.forEach((el) => el.classList.remove("abt-active"));
        abtFaq.classList.add("abt-active");
        textAbout.forEach((el) => (el.style.display = "none"));
        textTest.forEach((el) => (el.style.display = "none"));
        textFaq.forEach((el) => (el.style.display = "block"));
        setTestimonialsActive(false);
      }
    }
    page();

    // switch to FAQ tab when faq link is clicked
    document.querySelectorAll("a[href='#faq']").forEach((el) => {
      el.addEventListener("click", () => abtFaq.click());
    });

    //
    // GALLERY
    //

    // open gallery
    about1.addEventListener("click", () => gallery_view(1));

    // close gallery
    galleryviewbox.forEach((el) => el.addEventListener("click", gallery_close));
    galleryexit.addEventListener("click", gallery_close);
    // exit gallery when esc is pressed
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") gallery_close();
    });

    // switch gallery image
    leftarrow.addEventListener("click", leftimg);
    rightarrow.addEventListener("click", rightimg);

    // open gallery
    function gallery_view(n) {
      document.querySelector("#about").scrollIntoView();
      document.querySelector("html").style.overflow = "hidden";
      galleryview.style.display = "block";
      galleryview.style.zIndex = "10000";
      galleryview.style.opacity = "1";
      galleryviewbox.forEach((el) => {
        el.style.opacity = "0";
      });
      setTimeout(() => {
        document
          .querySelectorAll(".galleryviewbox:nth-child(" + p + ")")
          .forEach((el) => {
            el.style.opacity = "1";
          });
      }, 0);
      p = n;
    }

    // close gallery
    function gallery_close() {
      document.querySelector("html").style.overflow = "auto";
      galleryviewcont.style.transition = "0s";
      galleryview.style.opacity = "0";
      galleryview.style.zIndex = "-1";
      galleryviewbox.forEach((el) => {
        el.style.zIndex = "1";
        el.style.opacity = "0";
      });
      setTimeout(() => (galleryview.style.display = "none"), 500);
    }

    // previous image
    function leftimg() {
      if (!wait) {
        if (p > 1) {
          wait = true;
          galleryviewcont.style.transition = "0.6s";
          gallery_view(p - 1);
          setTimeout(() => {
            wait = false;
          }, 800);
        } else {
          wait = true;
          galleryviewcont.style.transition = "0.225s";
          galleryviewcont.style.left = "1.5%";
          setTimeout(function () {
            galleryviewcont.style.left = "0%";
          }, 225);
          setTimeout(() => {
            wait = false;
          }, 800);
        }
      }
    }

    //next image
    function rightimg() {
      if (!wait) {
        if (p < num) {
          wait = true;
          galleryviewcont.style.transition = "0.6s";
          gallery_view(p + 1);
          setTimeout(() => {
            wait = false;
          }, 800);
        } else {
          wait = true;
          galleryviewcont.style.transition = "0.225s";
          galleryviewcont.style.left = "-1.5%";
          setTimeout(function () {
            galleryviewcont.style.left = "0%";
          }, 225);
          setTimeout(() => {
            wait = false;
          }, 800);
        }
      }
    }

    // change about section
    abtAbout.addEventListener("click", () => setTab("main"));
    abtFaq.addEventListener("click", () => setTab("faq"));
    abtTest.addEventListener("click", () => setTab("test"));

    function setTab(page) {
      document.querySelector("#about").scrollIntoView();
      if (page === "main" && !abtAbout.classList.contains("abt-active")) {
        setTestimonialsActive(false);
        abtNav_button.forEach((el) => el.classList.remove("abt-active"));
        abtAbout.classList.add("abt-active");
        aboutTransition();
        setTimeout(() => {
          textAbout.forEach((el) => (el.style.display = "block"));
          textTest.forEach((el) => (el.style.display = "none"));
          textFaq.forEach((el) => (el.style.display = "none"));
        }, 550);
        window.history.replaceState("page", "", "/about?=main");
      } else if (page === "test" && !abtTest.classList.contains("abt-active")) {
        setTestimonialsActive(true);
        abtNav_button.forEach((el) => el.classList.remove("abt-active"));
        abtTest.classList.add("abt-active");
        aboutTransition();
        setTimeout(() => {
          textAbout.forEach((el) => (el.style.display = "none"));
          textTest.forEach((el) => (el.style.display = "flex"));
          textFaq.forEach((el) => (el.style.display = "none"));
        }, 550);
        window.history.replaceState("page", "", "/about?=testimonials");
      } else if (page === "faq" && !abtFaq.classList.contains("abt-active")) {
        setTestimonialsActive(false);
        abtNav_button.forEach((el) => el.classList.remove("abt-active"));
        abtFaq.classList.add("abt-active");
        aboutTransition();
        setTimeout(() => {
          textAbout.forEach((el) => (el.style.display = "none"));
          textTest.forEach((el) => (el.style.display = "none"));
          textFaq.forEach((el) => (el.style.display = "block"));
        }, 550);
        window.history.replaceState("", "", "/about?=faq");
      }

      function aboutTransition() {
        outer.style.opacity = "0";
        body.style.paddingBottom = `${outer.clientHeight}px`;

        setTimeout(() => {
          transitionAuto({
            element: outer,
            property: "height",
            value: 0,
          });

          setTimeout(() => {
            transitionAuto({
              element: outer,
              property: "height",
              value: "auto",
            });

            setTimeout(() => {
              outer.style.opacity = "1";
              body.style.paddingBottom = "0";
            }, 600);
          }, 600);
        }, 500);
      }
    }
  // eslint-disable-next-line
  }, []);

  return (
    <section id="about">
      <h1>ABOUT</h1>
      <div className="abt-nav">
        <a href="/about?=main" onClick={(e) => e.preventDefault()}>
          <button id="abt-about" className="abt-active">
            ABOUT ME
          </button>
        </a>
        <a href="https://imdb.com" target="_blank" rel="noopener noreferrer">
          <button id="abt-imdb">RESUME</button>
        </a>
        <a href="/about?=faq" onClick={(e) => e.preventDefault()}>
          <button id="abt-faq">FAQ</button>
        </a>
        <a href="/about?=testimonials" onClick={(e) => e.preventDefault()}>
          <button id="abt-test">TESTIMONIALS</button>
        </a>
      </div>
      <div className="outer">
        <div className="abt-main inner">
          <div className="abt-images text-about">
            <img
              width="180px"
              height="180px"
              id="about1"
              src="/images/about-image-1.png"
              alt=""
            />
          </div>
          <div id="galleryview">
            <div id="leftarrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="66"
                viewBox="0 0 35 66"
                fill="none"
              >
                <path
                  style={{ transition: "0.5s" }}
                  d="M34 65L2 33L34 1"
                  stroke="#DDDDDD"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <div id="galleryviewcont">
              <div className="galleryviewbox">
                <a
                  href="images/gallery-image-1.png"
                  target="_blank"
                  style={{ display: "contents" }}
                >
                  <img
                    src="/images/gallery-image-1.png"
                    alt="GalleryImage1"
                    className="galleryviewimg"
                  />
                </a>
              </div>
            </div>
            <div id="rightarrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="66"
                viewBox="0 0 35 66"
                fill="none"
              >
                <path
                  style={{ transition: "0.5s" }}
                  d="M1 1L33 33L1 65"
                  stroke="#DDDDDD"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <div id="galleryexit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path
                  style={{ transition: "0.5s" }}
                  d="M1 1L33 33M33 1L1 33"
                  stroke="#DDDDDD"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
          <div className="abt-text">
            <p className="text-about">
              Born on January 16, 1989, in the United States, Joshua Kraft is an
              aspiring American songwriter, level designer, writer, and
              cinematic music composer. While he enjoys all of these activities,
              his one true passion is music. Drawing inspiration from the
              musical works of Nobuo Uematsu, James Horner, and Blake Neely, his
              musical journey ignited when he discovered his love for music at
              the age of 20, propelling him into a lifelong pursuit of musical
              expression.
            </p>
            <p className="text-about">
              Within the realm of his passion, Joshua specializes in creating
              captivating atmospheres tailored for television, cinema, and video
              games. His private studio, located within the heart of the United
              States, serves as the canvas for his versatile compositions,
              spanning a multitude of genres, from immersive ambient soundscapes
              to grand orchestral scores. Each composition carries Joshua's
              distinct musical signature, infused with unwavering dedication to
              evoke emotion and enhance every project.
            </p>
            <p className="text-about">
              Unfortunately, this passion has brought many challenges, including
              personal obstacles, hardware-related issues, and a lack of
              guidance. Nevertheless, Joshua's devotion to music remains
              steadfast. He has crafted numerous distinctive pieces for personal
              projects and continues to expand his creative horizons with
              upcoming projects.
            </p>
            <p className="text-about">
              Joshua's ultimate goal is to demonstrate his intense dedication to
              the art of music by creating scores that capture the full spectrum
              of human emotions for television, cinema, and video games for you
              to experience.
            </p>
            <p className="text-about">
              For additional information or inquiries, please don't hesitate to
              reach out via the <a href="#contact">contact form</a>, visit
              the&nbsp;
              <a href="#faq">frequently asked questions</a> page, or email him
              at&nbsp;
              <a href="mailto:support@jvkstudios.com">support@jvkstudios.com</a>
              .
            </p>

            <div className="text-test">
              <Testimonials testimonialsActive={testimonialsActive} />
            </div>

            <div className="text-faq">
              <FAQs />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
